<template>
  <div>
    <!--    表单-->
    <BaseForm
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      ref="fromdata"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    ></BaseForm>
    <div class="main-page-table__header">
      <base-button
        label="新增"
        @click="addSupplier({url:'/addsupplier',type:'add'})"
        icon="el-icon-plus"
      ></base-button>
    </div>
    <!--    表格-->
    <Basetable
      :columns="columns"
      :api="api"
      :getApi="'getPage'"
      :showPage="true"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="false"
      class="main-page-table with-add"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton
          @click.prevent="detailsInfo(scope.row.keyId)"
          content="查看"
          icon="iconfont iconchakan"
        ></IconButton>
        <IconButton
          @click.prevent="updataInfo(scope.row.keyId)"
          content="编辑"
          icon="iconfont iconbianji3"
        ></IconButton>
        <IconButton
          @click.prevent="removeListItem(scope.row.keyId)"
          content="删除"
          icon="iconfont iconshanchu1"
        ></IconButton>
        <el-dropdown @command="(type) => handleItemClick(scope.row, type)" trigger="click">
          <IconButton icon="iconfont el-icon-more"></IconButton>
          <el-dropdown-menu slot="dropdown" class="more-dropdown">
            <el-dropdown-item command="changeData">变更信息</el-dropdown-item>
            <el-dropdown-item command="execute">被执行人</el-dropdown-item>
            <el-dropdown-item command="limited">限制消费令</el-dropdown-item>
            <el-dropdown-item command="assets">企业资产信息</el-dropdown-item>
            <el-dropdown-item command="liabilities">企业负债信息</el-dropdown-item>
            <el-dropdown-item command="property">外部地产情况</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Basetable>
    <!-- 变更、被执行人、限制消费令 -->
    <ChangeDataDialog :visible.sync="changeDataDialogVisible" v-bind="changeDataInfo"></ChangeDataDialog>
    <!-- 企业资产信息 -->
    <AssetsInfoDialog :visible.sync="assetsInfoDialogVisible" :companyId="rowCompanyId"></AssetsInfoDialog>
    <!-- 企业负债信息 -->
    <LiabilitiesSituationDialog :visible.sync="liabilitiesDialogVisible" :companyId="rowCompanyId"></LiabilitiesSituationDialog>
    <!-- 外部地产信息 -->
    <OutPropertyDialog :visible.sync="outPropertyDialogVisible" :companyId="rowCompanyId"></OutPropertyDialog>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form'
import Basetable from '@/components/common/table/base-table/base-table'
import { fromConfig, columnsConfig } from './utils/config.js'
import { supplierApi } from '@/api/companyApi'
import ChangeDataDialog from './components/change-data-dialog.vue'
import AssetsInfoDialog from './components/assets-info-dialog.vue'
import LiabilitiesSituationDialog from './components/liabilities-situation-dialog.vue'
import OutPropertyDialog from './components/out-property-dialog.vue'

export default {
  name: 'supplierManagement',
  components: {
    BaseForm,
    Basetable,
    IconButton,
    BaseButton,
    ChangeDataDialog,
    AssetsInfoDialog,
    LiabilitiesSituationDialog,
    OutPropertyDialog
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        enterpriseLabel: 1
      },
      loadCount: 0,
      tableData: [],
      changeDataDialogVisible: false,
      changeDataInfo: {
        companyName: '',
        companyId: '',
        ChangeInfoVOList: [],
        dialogTitle: '企业信息变更记录',
        type: 'enterprise-information-change'
      },
      assetsInfoDialogVisible: false, // 企业资产信息弹窗
      rowCompanyId: '',
      liabilitiesDialogVisible: false, // 企业负债弹窗
      outPropertyDialogVisible: false // 外部地产情况弹窗
    }
  },
  mounted () {
    this.handleFilter()
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromConfig(this)
    },
    api () {
      return supplierApi
    }
  },
  methods: {
    /**
     * 查询
     */
    handleFilter () {
      this.loadCount++
    },
    /**
     * 重置
     */
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    },
    /**
     * 新增供应商
     * @param data
     */
    addSupplier (data) {
      this.$router.push({
        path: data.url,
        query: { type: data.type }
      })
    },
    /**
     * 删除
     * @param keyId
     */
    removeListItem (keyId) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        supplierApi.del({ keyId }).then((res) => {
          this.success('删除成功')
          if (res.code === '0') this.handleFilter()
        })
      }).catch(() => {
      })
    },
    /**
     * 编辑
     * @param keyId
     */
    updataInfo (keyId) {
      this.$router.push({
        path: '/addsupplier',
        query: {
          keyId: keyId,
          type: 'edit'
        }
      })
    },
    /**
     * 查看详情
     * @param keyId
     */
    detailsInfo (keyId) {
      supplierApi.get(keyId).then((res) => {
        this.$router.push({
          path: '/addsupplier',
          query: {
            keyId: keyId,
            type: 'details'
          }
        })
      })
    },
    /**
     * 更多下拉点击
     * @param {string} type
     */
    handleItemClick (row, type) {
      type === 'changeData' && this.changeData(row)
      type === 'execute' && this.execute(row)
      type === 'limited' && this.limited(row)
      type === 'assets' && this.assets(row)
      type === 'liabilities' && this.liabilities(row)
      type === 'property' && this.property(row)
    },
    /**
     * 变更信息
     */
    changeData (row) {
      this.changeDataInfo = {
        companyName: row.enterpriseName,
        companyId: row.keyId,
        ChangeInfoVOList: [],
        dialogTitle: '企业信息变更记录',
        type: 'enterprise-information-change'
      }
      this.changeDataDialogVisible = true
    },
    /**
     * 被执行人
     */
    execute (row) {
      this.changeDataInfo = {
        companyName: row.enterpriseName,
        companyId: row.keyId,
        ChangeInfoVOList: [],
        dialogTitle: '被执行人记录',
        type: 'executor-record'
      }
      this.changeDataDialogVisible = true
    },
    /**
     * 限制消费令
     */
    limited (row) {
      this.changeDataInfo = {
        companyName: row.enterpriseName,
        companyId: row.keyId,
        ChangeInfoVOList: [],
        dialogTitle: '限制消费令记录',
        type: 'consumption-restriction-order'
      }
      this.changeDataDialogVisible = true
    },
    /**
     * 企业资产信息
     */
    assets (row) {
      this.rowCompanyId = row.keyId
      this.assetsInfoDialogVisible = true
    },
    /**
     * 企业负债信息
     */
    liabilities (row) {
      this.rowCompanyId = row.keyId
      this.liabilitiesDialogVisible = true
    },
    /**
     * 外部地产情况
     */
    property (row) {
      this.rowCompanyId = row.keyId
      this.outPropertyDialogVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>

.pd15 {
  padding: 15px;
}
</style>
<style lang="scss">
.more-dropdown{
  .el-dropdown-menu__item{
    height: 40px;
    line-height: 40px !important;
    // color: #FFFFFF !important;
    &:hover{
      background-color: #4A7CF0 !important;
      color: #FFFFFF;
    }
  }
}
.iconfont {
  &.el-icon-more{
    font-family: element-icons !important;
  }
}
</style>
