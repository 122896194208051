<template>
  <div>
    <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      :title="dialogTitle"
    >
      <base-form
        :componentList="addFrom"
        :showBtns="true"
        :formAttrs="{
          model: addQueryParas,
          labelWidth: '120px',
        }"
        @handleFilter="handleFilter('reset')"
        @clearParams="clearParams"
        class="formStyle"
        ref="changeFromData"
      ></base-form>
      <div class="addBtn">
        <div
          class="addButton"
          style="margin-right: 10px"
          @click="synchronization()"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="同步天眼查"
            placement="top"
          >
            <i class="iconfont iconshuaxin"></i>
          </el-tooltip>
        </div>
        <div class="addButton" @click="addddProjectCommentVOs()">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <base-table
        :showPage="true"
        :columns="addTable"
        :api="api"
        :getApi="'getPage'"
        :loadCount="loadCount"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        @select="select"
        :queryParas.sync="queryParams"
        @select-all="change"
        :dataSource.sync="tableData"
        ref="recordTableData"
        :isCaculateHeight="false"
        :webPage="false"
      >
        <template slot="finAttachmentList" slot-scope="scope">
          <!-- 文件列按钮组 -->
          <file-button-group
            :data="scope.row.finAttachmentList"
            @Upload="(data) => Upload(data, scope.row)"
            @deleteTemplate="(data) => deleteTemplate(data, scope.row)"
            @dowload="dowloadFile"
            :openUpload="true"
          />
        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            content="编辑"
            icon="iconfont iconbianji3"
            @click="addChangeRecordData(scope.row)"
          />
          <icon-button
            v-if="scope.row.dataSources !== 'TIANYANCHA'"
            content="删除"
            icon="iconfont iconshanchu1"
            @click="delChangeRecordData(scope.row)"
          />
        </template>
      </base-table>
      <template slot="footer">
        <base-button
          label="关闭"
          type="default"
          @click="visibleState = false"
        />
      </template>
      <update-data-dialog
        :dialogVisible.sync="updateDataShow"
        :formData="addData"
        :dataType="dataType"
        :Title="Title"
        :type="type"
        :companyId="companyId"
        :companyName="companyName"
        @refreshList="refreshList"
      />
    </base-dialog>
  </div>
</template>

<script>

import { downFile } from '@/utils/devUtils'
import UpdateDataDialog from './update-data-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import FileButtonGroup from './new-file-button-group.vue'
import { addFrom, addTable } from '../utils/enterprise-information-config'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { downAPi, fileAPi } from '@/api/fileApi'
import { supplierApi, supplierChange, supplierExecutor, supplierLimitConsumption } from '@/api/companyApi'
import { testFile } from '../utils/file-valid'
export default {
  components: {
    BaseDialog,
    BaseForm,
    BaseTable,
    FileButtonGroup,
    IconButton,
    BaseButton,
    UpdateDataDialog
  },
  props: {
    companyName: String, // 供应商名称
    type: String,
    visible: Boolean,
    dialogTitle: String,
    companyId: String,
    ChangeInfoVOList: Array // 父组件已选择的数据
  },
  data () {
    return {
      selectData: [], // 已选择的数据
      addData: {},
      addQueryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      updateDataShow: false,
      tableData: [],
      Title: '',
      dataType: '',
      loadCount: 0,
      queryParams: {}
    }
  },
  // 计算属性 类似于data概念
  computed: {
    api () {
      let api
      if (this.type === 'enterprise-information-change') {
        // 企业变更记录
        api = supplierChange
      } else if (this.type === 'consumption-restriction-order') {
        // 限制消费令记录
        api = supplierLimitConsumption
      } else if (this.type === 'executor-record') {
        // 执行人记录
        api = supplierExecutor
      }
      return api
    },
    addFrom () {
      return addFrom(this)
    },
    addTable () {
      return addTable(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.clearParams()
          this.tableData = []
          this.selectData = []
        })
      }
    }

  },
  // 方法集合
  methods: {
    // 回显已勾选的数据
    toggleRowSelection () {
      this.$nextTick(() => {
        this.ChangeInfoVOList.forEach((item) => {
          this.tableData.forEach((elem, index) => {
            elem.companyChangeId = elem.keyId
            if (item.keyId === elem.keyId) {
              elem.chose = true
              this.$refs.recordTableData.toggleRowSelection(elem, true)
            }
          })
        })
      })
      this.selectData = this.selectData.concat(
        JSON.parse(JSON.stringify(this.ChangeInfoVOList))
      )
      this.selectData.forEach((item) => {
        if (this.type === 'enterprise-information-change') {
          item.keyId = item.companyChangeId
        } else if (this.type === 'consumption-restriction-order') {
          item.keyId = item.companyLimitConsumptionId
        } else if (this.type === 'executor-record') {
          item.keyId = item.companyExecutorInfoId
        }
      })
      this.selectData = Array.from(new Set(this.selectData))
    },
    // 删除
    delChangeRecordData (row) {
      this.$confirm('是否删除当前数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.api.remove({ keyId: row.keyId }).then((res) => {
            this.success('删除成功')
            this.refreshList()
          })
        })
        .catch(() => {})
    },
    // 重载列表
    refreshList () {
      this.handleFilter('rest')
    },
    // 新增数据
    addddProjectCommentVOs () {
      this.dataType = 'add'
      this.addData = {
        enterpriseId: this.companyId,
        companyName: this.companyName
      }
      if (this.type === 'enterprise-information-change') {
        // 企业变更记录
        this.Title = '新增企业信息变更记录'
      } else if (this.type === 'consumption-restriction-order') {
        // 限制消费令记录
        this.Title = '新增限制消费令记录'
      } else if (this.type === 'executor-record') {
        // 执行人记录
        this.Title = '新增被执行人记录'
      }
      this.updateDataShow = true
    },
    // 修改数据
    addChangeRecordData (row) {
      this.dataType = 'change'
      this.addData = row
      this.addData.enterpriseId = this.companyId
      this.addData.companyName = this.companyName
      if (this.type === 'enterprise-information-change') {
        // 企业变更记录
        this.Title = '编辑企业信息变更记录'
      } else if (this.type === 'consumption-restriction-order') {
        // 限制消费令记录
        this.Title = '编辑限制消费令记录'
      } else if (this.type === 'executor-record') {
        // 执行人记录
        this.Title = '编辑被执行人记录'
      }
      this.updateDataShow = true
    },
    // 同步天眼查
    synchronization () {
      const types = {
        'enterprise-information-change': 1,
        'consumption-restriction-order': 2,
        'executor-record': 3
      }
      supplierApi.synchronousTyc({
        enterpriseId: this.companyId,
        enterpriseName: this.companyName,
        synchType: types[this.type]
      }).then((res) => {
        this.success('同步成功')
        this.refreshList()
      })
    },
    dowloadFile (data) {
      const fileId = data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    deleteTemplate (data, row) {
      const index = row.finAttachmentList.findIndex(s => s.fileId === data.fileId)
      row.finAttachmentList.splice(index, 1)
      this.api.saveOrUpdate({
        ...row,
        companyName: this.companyName,
        enterpriseId: this.companyId
      }).then(() => {
        this.success('文件刪除成功')
        this.refreshList()
      })
    },
    Upload (data, row) {
      if (testFile(data)) {
        const formData = new FormData()
        formData.append('file', data.file)
        formData.append('belongCode', 'TEMPLATE')
        fileAPi.file(formData).then((res) => {
          if (res.code === '0') {
            row.finAttachmentList = [
              { fileId: res.data.keyId, fileName: res.data.fileName }
            ]
            this.api.saveOrUpdate({
              ...row,
              companyName: this.companyName,
              enterpriseId: this.companyId
            }).then(() => {
              this.success('文件上传成功')
              this.refreshList()
            })
          } else {
            this.error('文件上传失败')
          }
        })
      }
    },
    // 改变选项
    select (selection, row) {
      if (
        this.selectData.some((item) => {
          return item.keyId === row.keyId
        })
      ) {
        // 已存在 则删除掉这一条
        let delIndex = ''
        this.selectData.forEach((item, index) => {
          if (item.keyId === row.keyId) {
            delIndex = index
            this.selectData.splice(delIndex, 1)
          }
        })
      } else {
        this.selectData.push(row)
      }
      console.log(this.selectData, '33333333')
    },
    change (data) {
      console.log(data, data.length, this.tableData, this.selectData, 'CCC')
      if (data.length === 0) {
        this.$nextTick(() => {
          this.tableData.forEach((item) => {
            for (let i = 0; i < this.selectData.length; i++) {
              console.log(this.selectData[i].keyId === item.keyId)
              if (this.selectData[i].keyId === item.keyId) {
                this.selectData.splice(i, 1)
                i--
              }
            }
          })
        })
        console.log('执行', this.selectData)
      } else {
        const newArr = []
        this.tableData.forEach((item) => {
          if (
            this.selectData.some((elem) => {
              return elem.keyId === item.keyId
            })
          ) {
            // 已存在
          } else {
            newArr.push(item)
          }
        })
        this.selectData = JSON.parse(
          JSON.stringify(this.selectData.concat(newArr))
        )
      }
      console.log(this.selectData, 'this.selectData')
    },
    handleFilter (type) {
      console.log(this.type)
      this.addQueryParas.enterpriseId = this.companyId
      if (type) {
        this.addQueryParas.pageSize = 10
        this.addQueryParas.pageIndex = 1
      }
      this.queryParams = {
        ...this.addQueryParas,
        changeTimeStart: this.addQueryParas.changeTime?.[0],
        changeTimeEnd: this.addQueryParas.changeTime?.[1],
        changeTime: undefined
      }
      this.loadCount++
    },
    clearParams () {
      this.addQueryParas = {
        pageSize: 10,
        pageIndex: 1,
        enterpriseId: this.companyId
      }
      this.handleFilter('rest')
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.addBtn {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding-right: 19px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
