// 资产信息
<template>
  <div class="assetsInfo">
    <div class="head">
      <div class="addbutton" @click="addAssetsInfo">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="assetsInfolumn"
      :tableAttrs="{
        data: assetsInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="assetTypeHeader">
        <font style="color: #ee1c1c">*</font>资产类型
      </template>
      <template slot="ownerHeader">
        <font style="color: #ee1c1c">*</font>权属人
      </template>
      <template slot="isControllerHeader">
        <font style="color: #ee1c1c">*</font>是否实控人个人资产
      </template>
      <template slot="addressHeader">
        <font style="color: #ee1c1c">*</font>坐落/明细
      </template>
       <template slot="appraisalPriceHeader">
        <font style="color: #ee1c1c">*</font>评估价
      </template>
      <template slot="loanBalanceHeader">
        <font style="color: #ee1c1c">*</font>贷款余额（元）
      </template>
      <!-- 资产类型 -->
      <template slot="assetType" slot-scope="scope">
          <base-select
          v-model="scope.row.assetType"
          style="width: 180px; margin-right: 10px"
          :options="assetsTypeOption"
          :selectedField="['dictId', 'dictName']"
        ></base-select>
      </template>
      <template slot="isController" slot-scope="scope">
          <base-select
          v-model="scope.row.isController"
          style="width: 180px; margin-right: 10px"
          :options="isControllerOption"
          :selectedField="['key', 'label']"
        ></base-select>
      </template>
      <!-- 是否已抵押 -->
      <template slot="mortgageStatus" slot-scope="scope">
          <base-select
          v-model="scope.row.mortgageStatus"
          style="width: 180px; margin-right: 10px"
          :options="mortgageStatusOption"
          :selectedField="['dictId', 'dictName']"
        ></base-select>
      </template>
      <!-- 权证号 -->
       <template slot="warrantNumber" slot-scope="scope">
         <div v-if="scope.row.assetType!=='REAL_ESTATE'">/</div>
        <el-input
          v-else
          v-model="scope.row.warrantNumber"
          placeholder="请输入权证号"
          maxlength="100"
        ></el-input>
      </template>
      <!-- 权属人 -->
       <template slot="owner" slot-scope="scope">
        <el-input
          v-model="scope.row.owner"
          placeholder="请输入权属人"
          maxlength="20"
        ></el-input>
      </template>
      <!-- 坐落/明细 -->
       <template slot="address" slot-scope="scope">
        <el-input
          v-model="scope.row.address"
          placeholder="请输入坐落/明细"
          maxlength="200"
        ></el-input>
      </template>
      <!-- 评估价 -->
       <template slot="appraisalPrice" slot-scope="scope">
        <amount-input
          v-model="scope.row.appraisalPrice"
          placeholder="请输入评估价"
          type='1000Y'
        ></amount-input>
      </template>
      <!-- 贷款余额（元） -->
       <template slot="loanBalance" slot-scope="scope">
        <amount-input
          v-model="scope.row.loanBalance"
          placeholder="请输入贷款余额"
          type="10Y"
        ></amount-input>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          content="删除"
          icon="iconfont iconshanchu1"
          @click="delAssetsItem(scope.$index)"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { assetsInfoTable } from '../utils/enterprise-info-config'
import { getDictLists } from '@/filters/fromDict'
export default {
  props: {
    tableData: Array
  },
  components: { baseTable, IconButton, BaseSelect, AmountInput },
  data () {
    return {
      isControllerOption: [{ label: '是', key: '1' }, { label: '否', key: '0' }]
      // assetsInfoData: [] // 资产信息表格
    }
  },
  computed: {
    assetsInfolumn () {
      return assetsInfoTable(this)
    },
    assetsTypeOption () {
      return getDictLists('ASSET_TYPE')
    },
    mortgageStatusOption () {
      return getDictLists('HYPOTHECATE_STATE')
    },
    assetsInfoData: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('changeAssetsInfoData', val)
      }
    }
  },
  methods: {
    // 删除数据
    delAssetsItem (index) {
      this.assetsInfoData.splice(index, 1)
    },
    // 增加一条数据
    addAssetsInfo () {
      this.assetsInfoData.push({ add: true })
    }
  }
}
</script>
<style lang="scss" scoped>
.assetsInfo {
  padding: 10px;
}
.assestArea {
  margin-top: 20px;
}
.head {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
