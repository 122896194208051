<template>
  <!-- 模板文件操作按钮组 -->
  <div>
       <div class="fileButtonGroup"  v-for="(item,index) of data" :key="index">
    <!-- 文件名称 -->
    <div class="name">
      <el-tooltip
        effect="dark"
        placement="top"
        :enterable="false"
        :hide-after='3000'
        :content="item.fileName">
        <span >{{item.fileName}}</span>
      </el-tooltip>
    </div>
    <!-- 操作按钮 -->
    <span class="content">
      <icon-button
        content="预览"
        icon="iconfont iconyulan"
        @click="previewShow(item)"
        v-if="item.fileId !== '0'&& item.fileId"
      />

      <!-- 上传文件 -->
      <el-tooltip

        class=""
        effect="dark"
        :enterable="false"
        :content="item.fileId !== '0'&& item.fileId? '重新上传':'上传'"
        placement="top"
        v-if="openUpload&&banUpload!==0&&MakeType!=='showInfo'"
      >
        <base-button
          type="text"
          label=""
          :icon="item.fileId !== '0'&& item.fileId?'iconfont iconzhongxinshangchuan':'iconfont iconshangchuan'"
          btnType="upload"
          accept=".doc,.docx,.xlsx,.xls,.pdf,.png,.jpg,.jpeg,.png,.bmp"
          style="margin: 0px 10px"
          action="#"
          :http-request="function (url) {
              return Upload(url,item);
            }
          "
        />
      </el-tooltip>
      <!-- 下载 -->
      <icon-button
        content="下载"
        icon="iconfont iconxiazai"
        @click="dowload(item)"
        v-if="item.fileId !== '0'&& item.fileId"
      />
      <!-- 删除 -->
      <icon-button
        @click="deleteTemplate(item)"
        content="删除文件"
        icon="iconfont iconshanchu2"
        v-if="item.fileId !== '0'&& item.fileId&&banUpload!==0&&MakeType!=='showInfo'&&openUpload"
      />
    </span>
  </div>
   <!-- 文件预览 -->
    <pre-view
      :fileId="previewData.fileId"
      :isOpen="true"
      :fileType="previewData.fileType"
      :count="preViewcount"
    />
  </div>

</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: {
    IconButton,
    BaseButton,
    PreView
  },
  props: {
    data: Array,
    currentManagerId: String,
    MakeType: String,
    openUpload: {
      type: Boolean,
      default: true
    },
    index: Number,
    banUpload: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      previewData: {},
      preViewcount: 0
    }
  },
  mounted () {
  },
  methods: {

    // 预览
    previewShow (data) {
      data.fileType = data.fileName.replace(/.+\./, '')
      console.log(data)
      this.previewData = data
      this.preViewcount++
    },
    // 文件上传
    Upload (param, data) {
      console.log(param, data, 'data')
      this.$emit('Upload', param, data, this.index) // keyIdF 文件项KeyId
    },
    // 文件删除
    deleteTemplate (data) {
      this.$emit('deleteTemplate', data)
    },
    // 文件下载
    dowload (row) {
      this.$emit('dowload', row)
    }
  }
}
</script>
<style lang="scss" scoped>
.fileButtonGroup {
  display: flex;
  align-items: center;
  .name {
    width: calc(100% - 106px);
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  .content {
    width: 160px;
  }
}
</style>
