<!-- 外部地产弹窗 -->
<template>
  <BaseDialog :visible.sync="_visible" :title="dialogTitle" :showFooter="false">
    <OutProperty :tableData="tableData" @changePropertyInfoData="tableDataChange"></OutProperty>
    <template slot="footer">
      <base-button label="确定" @click="submit" />
      <base-button
        label="关闭"
        type="default"
        @click="_visible = false"
      />
    </template>
  </BaseDialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import OutProperty from '@/pages/business/report-production-make/components/out-property.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { supplierApi } from '@/api/companyApi'

export default {
  components: {
    BaseDialog,
    OutProperty,
    BaseButton
  },
  props: {
    visible: Boolean,
    dialogTitle: {
      type: String,
      default: '外部地产信息'
    },
    companyId: String
  },
  data () {
    return {
      tableData: []
    }
  },
  computed: {
    _visible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.tableData = []
        this.initTableData()
      }
    }
  },
  methods: {
    // 初始化表格数据
    initTableData () {
      supplierApi.getExtEstateListByEnterpriseId({ enterpriseId: this.companyId }).then(res => {
        this.tableData = res.data
      })
    },
    // 表格数据变更
    tableDataChange (data) {
      this.tableData = data
    },
    validate () {
      return !this.tableData?.some(item =>
        (!item.name || !item.constructionProjectsNumber || !item.totalContractAmount ||
          !item.totalReceivablesSettlements || !item.businessProportion || !item.paymentType || !item.paymentCycle)
      )
    },
    // 确定 提交
    submit () {
      if (this.validate()) {
        const list = JSON.parse(JSON.stringify(this.tableData))
        const enterpriseId = this.companyId
        list.forEach(it => {
          it.enterpriseId = enterpriseId
        })
        supplierApi.batchSubmitEstate(list, enterpriseId).then(res => {
          if (res.data) {
            this.success('保存外部地产情况成功')
            this._visible = false
          }
        })
      } else {
        this.warning('请完善外部地产情况')
      }
    }
  }
}
</script>
<style lang="scss">

</style>
